import './badge.css';

interface BadgeProps {
    label: string;
    variant: "brand" | "primary" | "secondary" | "success" | "info" | "warning" | "danger";
    pill?: boolean;
}

export const Badge = ({
    label,
    variant,
    pill = false
}: BadgeProps) => {
    return (
        <div className={`badge ${pill ? "badge-pill" : ""} badge-${variant}`}>
            {label}
        </div>
    )
}