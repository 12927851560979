import Pagination from "./pagination";
export default class ExamRequest {
  seriesCode: string;
  seriesName: string;
  qualificationTypeCode: string;
  qualificationTypeName: string;
  displayPaperCount: number
  subjectCount: number
  totalPaperCount: number;
  reply?:{
      totalRecords: number;
    }
  papers: [{
    allocationId: number
    aaName: string
    aaNumber: string
    actualLearnerEvidenceCount: 0
    expectedLearnerCount: 0
    evidenceSubmittedBy: string
    evidenceSubmittedOn: string
    qualificationCode: string
    qualificationName: string
    status: string
    statusId: string
    submissionDeadlineDate: string
    unitCode: string
    unitName: string,
    evidenceScanningCount:number,
    evidenceAcceptedCount:number,
    evidenceRejectedCount:number,
    returnedBy: string,
    returnedOn:string;
  }]
  lpAdminEvidenceStatus: "None" | "Unlocked" | "Resubmitted" | null
}

export class SubjectCountToShow {
  [key: string]: number;
}

export class ExamRequestsSummary {
  learningProviderCode: string;
  examination: {
    toDoCount: number;
    inProgressCount: number;
    completedCount: number;
  };
}

export class ViewPapers {
  qualificationTypeCode: string;
  qualificationTypeName:string;
  seriesCode :string;
  seriesName:string;
  subjectCount  :string;
    pageInfo: Pagination;
    totalRecords: number;
    pageNumber: number;
    totalPages: number;  
  papers: [{
    allocationId: number
    aaName: string
    aaNumber: string
    actualLearnerEvidenceCount: 0
    expectedLearnerCount: 0
    evidenceSubmittedBy: string
    evidenceSubmittedOn: string
    qualificationCode: string
    qualificationName: string
    status: string
    statusId: string
    submissionDeadlineDate: string
    unitCode: string
    unitName: string,
    evidenceScanningCount:number,
    evidenceAcceptedCount:number,
    evidenceRejectedCount:number
  }]
}
