import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";

import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import {LearnerML, SaveLearners, SaveLinkLearners}  from "../../../models/learner";
import {dateFormatter} from "../../../helpers/dashboard-request-helper";
import * as llHlpr from "../link-learners.helper";
import SharedFileLearners from "../../../models/shared-file";
import _, { update } from "lodash";

// interface GetLinkLearnersDetailsAction extends Action {
//   type: actionTypes.GET_LINK_LEARNER_DETAILS_SUCCESS;
//   linkLearnersDetailsData: LearnerML;
// }

interface UpdateLLLoaderAction extends Action {
  type: actionTypes.UPDATE_LINK_LEARNER_LOADER_SUCCESS;
  isShowLLLoader : boolean;
}

interface UpdateLinkLearnerAction extends Action {
  type: actionTypes.UPDATE_LINK_LEARNER_SUCCESS;
  selectedLearnersData: number[];
}

interface SaveLinkLearnerAction extends Action{
  type: actionTypes.SAVE_LINK_LEARNER_SUCCESS;
  saveLinkLearnersData: SaveLinkLearners;
}
interface EmptySelectedLinkLearnersAction extends Action {
  type: actionTypes.GET_LINK_LEARNER_DETAILS_SUCCESS;
  linkLearnersDetailsData: LearnerML[];
}

interface GetSharedFileLearnersAction extends Action {
  type: actionTypes.GET_SHARED_FILE_LEARNERS_SUCCESS;
  sharedFileLearnersData: SharedFileLearners;
}

export type LinkLearnersAction = 
  // | GetLinkLearnersDetailsAction
  | UpdateLLLoaderAction
  | UpdateLinkLearnerAction
  | EmptySelectedLinkLearnersAction
  | SaveLinkLearnerAction
  | GetSharedFileLearnersAction;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, LinkLearnersAction>;



// export const GetLinkLearnersDetailsSuccessAction = (
//   linkLearnersDetailsData: LearnerML
// ): GetLinkLearnersDetailsAction => ({
//   linkLearnersDetailsData,
//   type: actionTypes.GET_LINK_LEARNER_DETAILS_SUCCESS,
// });

export const UpdateLLLoaderSuccessAction = (
  isShowLLLoader: boolean
): UpdateLLLoaderAction => ({
  isShowLLLoader,
  type: actionTypes.UPDATE_LINK_LEARNER_LOADER_SUCCESS
});

export const UpdateLinkLearnerSuccessAction = (
  selectedLearnersData: number[]
): UpdateLinkLearnerAction => ({
  selectedLearnersData,
  type: actionTypes.UPDATE_LINK_LEARNER_SUCCESS
});

export const SaveLinkLearnerSuccessAction = (
  saveLinkLearnersData: SaveLinkLearners
): SaveLinkLearnerAction => ({
  saveLinkLearnersData,
  type: actionTypes.SAVE_LINK_LEARNER_SUCCESS
});

export const EmptySelectedLinkLearnersSuccessAction = (
  linkLearnersDetailsData: LearnerML[]
): EmptySelectedLinkLearnersAction => ({
  linkLearnersDetailsData,
  type: actionTypes.GET_LINK_LEARNER_DETAILS_SUCCESS,
});

export const GetSharedFileLearnersSuccessAction = (
  sharedFileLearnersData: SharedFileLearners
): GetSharedFileLearnersAction => ({
  sharedFileLearnersData,
  type: actionTypes.GET_SHARED_FILE_LEARNERS_SUCCESS
});


// export const getLinkLearnersDetails = (
//   allocationId: string,
  
// ): ThunkResult<Promise<void>> => {
//   return (dispatch) => {
//     const axiosConfig: AxiosRequestConfig = {
//       headers: { "content-type": "application/json" },
//     };
//     return axios
//       .post(
//         `${config.API_GATEWAY.URL}/link-learners/list/0`,
//         JSON.stringify({
//           allocationId,          
//         }),
//         axiosConfig
//       )
//       .then((postResponse) => {
//         const postResponseData = postResponse.data;
//         const linkLearnersDetailsData = postResponseData.map( (request: LearnerML)=>{
//           request.learnerDob = dateFormatter(request.learnerDob).date;
//           return request;
//         })
//         dispatch(GetLinkLearnersDetailsSuccessAction(linkLearnersDetailsData));
//       })
//       .catch((error) => {
//         console.log("GetLinkLearners Action Error==>", error);
//         // throw error;
//       });
//   };
// };

export const saveLinkLearnersDetails = (
  allocationId: number,
  fileKey: string,
  selectedLearners: number[],
  unSelectedLearners: number[]
): ThunkResult<Promise<void>> => {
  return async (dispatch: (arg0: SaveLinkLearnerAction) => void) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    /** Convert string array of Learner Ids to Int array */
    // const unSelectedLearnersNum = unSelectedLearners.map(s=>+s);
    // const selectedLearnersNum = selectedLearners.map(s=>+s);
    /** */
    try {
      const postResponse = await axios
        .post(
          `${config.API_GATEWAY.URL}/exam-shared-file-learners/save`,
          JSON.stringify({
            allocationId,
            fileKey,
            // selectedLearners:selectedLearnersNum,
            // unSelectedLearners:unSelectedLearnersNum          
            selectedLearners,
            unSelectedLearners
          }),
          axiosConfig
        );
      const saveLearnersResponse = postResponse.data as SaveLinkLearners;
      // console.log("Save:::",saveLearnersResponse);
      // console.log(localStorage.getItem("isLLMsgShow"),"::::Response SL");
      dispatch(SaveLinkLearnerSuccessAction(saveLearnersResponse));
    } catch (error) { }
  };
};

export const updateLLLoader = (
  isShowLLLoader: boolean
): UpdateLLLoaderAction => {
  return UpdateLLLoaderSuccessAction(isShowLLLoader);
};

export const updateLinkLearner = (
  selectedLearnersData: number[]  
): UpdateLinkLearnerAction => {
   return UpdateLinkLearnerSuccessAction(selectedLearnersData);
};

export const getSharedFileLearners = (
  allocationId: number,
  fileKey: string,
  assessmentMethodType: number,
  linkedLearnerFilter?: number
): ThunkResult<Promise<void>> => {
  return async (dispatch: (arg0: UpdateLLLoaderAction | GetSharedFileLearnersAction) => void) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    dispatch(updateLLLoader(true));    
    linkedLearnerFilter = linkedLearnerFilter||0;    
    try {
      const postResponse = await axios
        .post(
          `${config.API_GATEWAY.URL}/exam-shared-file-learners/shared-file-learners`,
          JSON.stringify({
            allocationId,
            fileKey,
            assessmentMethodType,
            linkedLearnerFilter
          }),
          axiosConfig
        );
      dispatch(updateLLLoader(false));
      // console.log("SharedFiles ACTION Learners-RAW::",postResponse.data.learners)
      const postResponseData = postResponse.data as SharedFileLearners;
      /************************ */
      // MOCK MOCK MOCK 
      // to test the late entry learners in the link-learners.component.tsx
      /************************ */
      // const lateEntryLearners = ['1091','1029']
      // let mockLearners = postResponseData.learners.map( (l)=>{
      //   if(_.includes(lateEntryLearners, l.learnerCode)) {
      //     l.isLateEntry=true;
      //     l.learnerLateEntryDate='2021-05-15';
      //   }          
      //   return l;
      // })
      //  // Deep cloning needed, assignment operator copies references 
      //  postResponseData.learners = JSON.parse(JSON.stringify(mockLearners));      
      /************************ */
      /************************ */
      /************************ */
      if (postResponseData.responseCode + "" !== '410') {
        const sharedFileLearnersList = postResponseData.learners.map((l) => {
          l.searchableDob = l?.learnerDob !== null ? dateFormatter(l?.learnerDob + "").date : "NA";
          l.isDisabled = llHlpr.isDisableLearner(l);
          l.isDisabledLinked = llHlpr.isDisableLearnerLinked(l);
          return l;
        });
        // Deep cloning needed, assignment operator copies references 
        postResponseData.learners = JSON.parse(JSON.stringify(sharedFileLearnersList));
      }

      dispatch(GetSharedFileLearnersSuccessAction(postResponseData));
    } catch (error) { }
  };
};












