//import AdminMaterial from "./admin-material";
import { FILTER_LEARNER } from "../shared/app-enums";
import FileDetails from "./file-details";
import Pagination from "./pagination";

export default class Learner {
  learnerId: string;
  learningProviderId: string;
  qualificationId: string;
  code: string;
  firstName: string;
  surname: string;
  dateOfBirth: string;
  dob: string;
  gender: number = 0;
  genderId: number = 0;
  learningProviderCode: string;
  learningProviderName: string;
  learnerMark?: string;
  learnerStatusCode?: string;
  learnerStatusTitle?: string;
  evidence?: FileDetails[] = [];
  learnerFirstName: string;
  learnerLastName: string;
  learnerDob: string;
  learnerLateEntryDate: string;
  learnerIsLateEntry:string;
  isSelected?: boolean;
  isISeries?: boolean;
  isSystemSelected?: boolean;
  learnerEvidenceStatus: "None" | "Unlocked" | "Resubmitted" | null;
}

export class SelectedLearner {
  learnerId: string;
}

export class LearnersList {
  allocationId: string;
  learners: Learner[];
  pageInfo: Pagination;
  totalRecords: number;
  pageNumber: number;
  totalPages: number;
  qualificationTypeName: string;
  qualificationName: string;
  qualificationCode: string;
  qualificationTypeCode:string;
  unitCode: string;
  unitName: string;
  seriesName: string;
  learningProviderCode: string;
  learningProviderName: string;
  actualLearnerEvidenceCount: string;
  expectedLearnerCount: string;
  status:string;
  statusId: string;
  aaName:string;
  aaNumber:string;
  subjectCount: number | undefined;
  submissionDeadlineDate: string;
  totalPaperCount: number | undefined;
  learnersCount:LearnerSummary;
  returnedBy:string;
  returnedOn:string;
  userClassType: string;
  returnUserClassType: string;
  evidenceSubmittedBy: string;
  evidenceSubmittedOn: string;
  lpAdminEvidenceStatus: "None" | "Unlocked" | "Resubmitted" | null;
}

export class LearnerSummary {
  learner: {
    allLearners: number;
    accepted: number;
    rejected: number;
    scanning: number;
    presentWithNoFiles: number;
  }
}

// LearnerML = Learner type in manageLearner
export class LearnerML{
  isCentreSelected?: boolean;
  learnerCode: string;
  learnerDob: string;
  learnerFirstName: string;
  learnerId: number;
  learnerLastName: string;
  learnerMark: string;
  learnerStatusCode: string;
  learnerStatusTitle: string;
  learnerEvidenceStatus: "None" | "Unlocked" | "Resubmitted" | null;
}

export class LearnerLL extends LearnerML{
  isSharedFile?: boolean;
  responseCode?: number;
  isLateEntry?: boolean;
  learnerLateEntryDate?: string;
  searchableDob?:string; // used for date field search
  isDisabledLinked?: boolean; // used to disable in IN_PROG_C
  isDisabled?: boolean;
}

export class SaveLearners{
  hasError: Boolean;
  addAbortedLearners:[{
    learnerCode: string;
    learnerName: string;
  }];
  removeAbortedLearners:[{
    learnerCode: string;
    learnerName: string;
  }];
}

export class SaveLinkLearners{
  addAbortedLearners: [{
    isCentreSelected: boolean;
    learnerCode: string;
    learnerDob: string;
    learnerFirstName: string;
    learnerId: number;
    learnerLastName: string;
    learnerMark: string;
    learnerName: string;
    learnerStatusCode: string;
    learnerStatusTitle: string;
  }];
  fileKey: string;
  hasError: boolean;
  removeAbortedLearners: [{
    isCentreSelected: boolean;
    learnerCode: string;
    learnerDob: string;
    learnerFirstName: string;
    learnerId: number;
    learnerLastName: string;
    learnerMark: string;
    learnerName: string;
    learnerStatusCode: string;
    learnerStatusTitle: string;
  }];
  responseCode: number;
  responseText: string;
}

export type learnerFilterType = 
| FILTER_LEARNER.ALL 
| FILTER_LEARNER.SEL
| FILTER_LEARNER.UNSEL
| FILTER_LEARNER.LNKD
| FILTER_LEARNER.NONE