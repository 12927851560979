import * as React from "react";
import { useEffect } from "react";
import Icon from "./icon";
import logoText from "./pearsonLogo.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useHistory } from "react-router";
import Modal from './LogOutModal';
import { useUserLoginState } from "../../shared/context-api/hooks";
import {HasDlwtSetupReadAccess, HasReadAccess } from "../../components/common/user-validation";

interface HeaderProps {
  className: string;
  notifications?: number;
  name?: string;
  loggedIn: boolean;
  theme?: "light" | "dark";
  logolink?: string;
  applicationName?: string;
  logout: () => void;
  helpIconLink: string;
}

const Header = ({
  className,
  notifications,
  name,
  loggedIn,
  theme,
  logolink,
  applicationName,
  logout,
  helpIconLink,
}: HeaderProps) => {
  const headerRef: any = React.createRef();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData")!)
  const loginName = userData && userData.firstName! +" "+userData.lastName!;
  const [openSetupModal,showSetupModal] = React.useState<boolean>(false);
  const userLoginstate = useUserLoginState();

  const toolTipTextContent = (
    <span>
      {name}
      <br />
      {notifications} notifications
    </span>
  );

  useEffect(() => {
    if(headerRef.current && userData && userData.firstName){
      headerRef.current.addEventListener("logout", () => {
        logout();
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const onHelpLink = () =>{
    window.open(helpIconLink, "_blank");
  }

  const onDlwtSetupLink = () =>{
    localStorage.removeItem("IsSetupChanged");
    history.push("/assessment-deadline-config");
    closeOutSetupModalWindow();
  }

  const closeOutSetupModalWindow = () => {
    showSetupModal(false);
    
  }; 
  const openSetupModalWindow = () => {    
    showSetupModal(true);    
  };

  const ShowProductSetupModal = () =>{
    let IsSetupChanged=localStorage.getItem("IsSetupChanged");
    if(IsSetupChanged==="true")
    {
        openSetupModalWindow();
    } 
    else
    {
      onDlwtSetupLink();
    }
  }

  // const profile = getProfile();
  // name = profile.name || profile.identities[0].userId
  
  return (
    <div className="header">
       <Modal hideModalData={true} isConfirmBut={true} show={openSetupModal} title="Confirmation" modalData="Log Out" isSetupModal={true} onCancel={() => closeOutSetupModalWindow()} onConfirm={() => onDlwtSetupLink()} />
      {userData && userData.firstName && (
      <pearson-header
        style={{ background: "#fff" }}
        ref={headerRef}
        notifications={notifications!.toString()}
        name={loginName}
        theme={theme}
        logolink={logolink}
        loggedIn={loggedIn ? "true" : "false"}
      >
        <span className="logoText">
          <img alt="Pearson" src={logoText} className="logoTextSize" />{" "}
        </span>
        <span className="appTextName">| Learner Work Transfer</span>
        <div className="headerIcons">
          <span className="helpIconWrapper">
         
          {/* {userData.isInternalUserHasWriteAccess && (
          <Link href="/assessment-deadline-config">Setup DLWT Products</Link>
          // <Icon size="18" icon="settings" onClkEvt={onHelpLink} linkClass="helpLink" />

          )} */}
          {/* <Button color="secondary" type="button" size="small">View</Button> */}
            {/* <a href={helpIconLink} target="_blank" rel="noopener noreferrer"> */}
            <Icon size="24" icon="help" onClkEvt={onHelpLink} linkClass="helpLink" />
            {HasReadAccess(userLoginstate) && HasDlwtSetupReadAccess(userLoginstate) && (
              <>
                {userData.internalUser && userData.isUserPermissionValid && (
                  <Icon size="24" icon="settings" onClkEvt={ShowProductSetupModal} linkClass="dlwtSetup" toolTip="Access to configuration screens to manage the setup of products that will use LWT and LWA" />
                )}
              </>
            )}  
          </span>
         
        </div>
      </pearson-header>)}
      <div className="toolTipWrapper2">
     
        <Tippy content={toolTipTextContent}>
          <span className="toolTipText"></span>
        </Tippy>
       
      </div>
      {/* <div className="toolTipWrapper">
        <span
          data-tippy-placement="bottom"
          data-tippy={toolTipText}
          data-tippy-arrow="true"
          className="toolTipText"
        ></span>
      </div> */}
    </div>
  );
};

Header.defaultProps = {
  notifications: 0,
  loggedIn: false,
  theme: "light",
};

export default Header;
