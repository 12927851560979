import React, { useEffect } from "react";
import S3UploaderAdminMaterial from "../pearson/uploader/s3uploader_adminmaterial";
import config from "../../../src/app-config";
import "./upload.css";
import Icon from "./icon";
import sizeInBytes from "../../helpers/sizeInBytes";
import * as avatsFileStatus from '../pearson/shared/constants';
import { FILE_USER_TYPE, REQUSEST_STATUS, FILE_STATUS } from '../../shared/app-enums';
import axios from "axios";
import S3UploadMultipart from "./uploader/s3-upload-multipart";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { HasWriteAccess } from "../../components/common/user-validation";

const FileUploadAdminMaterial = (props: any) => {
  const {
    allocationId,
    addAdminMaterial,
    removeAdminmaterial,
    prevUploadedAdminFiles,
    handlePostAddRemoveFiles,
    status,
    adminMaterial,
    unlocked,
    lpAdminEvidenceStatus,
    hideUnlockStatusPill
  } = props;
  const [fileUploadStatus, setFileUploadStatus] = React.useState([]);
  // eslint-disable-next-line
  // const [showAbortErrorMsg, setShowAbortErrorMsg] = React.useState(false);
  const [showCancelAll, setShowCancelAll] = React.useState(0)
  const apiEndPoint = `${config.API_GATEWAY.URL}/exam-file-upload-token/get-admin`;
  const [removeAdminFileNames, setRemoveAdminFileNames] = React.useState([]);
  const [isAdminFileUploaded, setIsAdminFileUploaded] = React.useState<boolean>(false);
  const userLoginstate = useUserLoginState();

  const cancelSingleUpload = (e: any, fileName: any, index: any) => {
    let updateUploadStatus: any = [...fileUploadStatus];
    removeSingleAdminFileLocalStorage(fileName);
    updateUploadStatus[index].cancelFile();
    updateUploadStatus[index].showProgress = false;
    updateUploadStatus[index].percentage = 0;
    updateUploadStatus[index].cancelUpload = true;
    setFileUploadStatus(updateUploadStatus);
    // setShowAbortErrorMsg(true);
    setRemoveFiles(fileName)
  };

  const cancelAll = () => {
    // console.log("cancel all called", fileUploadStatus)
    let cancelAllAdminNames: any = [];
    removeAdminFileLocalStorageForLearner();
    fileUploadStatus.forEach((file: any, index) => {
      if (file.percentage < 100 || isNaN(file.percentage)) {
        file.cancelFile();
        file.showProgress = false;
        file.percentage = 0;
        file.cancelUpload = true;
        cancelAllAdminNames.push(file.name)
      }
    });
    const cancelAllStatus = [...fileUploadStatus];
    setFileUploadStatus(cancelAllStatus);
    setRemoveAdminFileNames(cancelAllAdminNames)
    // setShowAbortErrorMsg(true);

  };

  const removeSpecialCharacters = (value: any) => {
    return value.replace(/[^\w\d_\-\.]+/gi, "")
  };

  const removeSingleAdminFileLocalStorage = (fileName: string) => {
    let localStorageFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
      : [];
    const adminMaterialIndex = localStorageFileUploaded.findIndex(
      (x: any) => x.section === "adminMaterial" && x.allocationId === allocationId
    );
    if (adminMaterialIndex !== -1) {
      const fullFileName = `adminMaterial/${allocationId}/` + removeSpecialCharacters(fileName);
      if (localStorageFileUploaded[adminMaterialIndex].files.length === 1) {
        localStorageFileUploaded.splice(adminMaterialIndex, 1);
      } else {
        const fileIndex = localStorageFileUploaded[adminMaterialIndex].files.findIndex(
          (file: any) => file.fullFileName === fullFileName
        );
        if (fileIndex !== -1) {
          localStorageFileUploaded[adminMaterialIndex].files.splice(fileIndex, 1);
        }
      }
    }

    localStorage.setItem(
      "adminfileUploaded",
      JSON.stringify(localStorageFileUploaded)
    );
  };

  const removeAdminFileLocalStorageForLearner = () => {
    let localStorageFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
      : [];

    const index = localStorageFileUploaded.findIndex(
      (x: any) => x.section === "adminMaterial" && x.allocationId === allocationId
    );
    if (index !== -1) {
      localStorageFileUploaded.splice(index, 1);
    }

    localStorage.setItem(
      "adminfileUploaded",
      JSON.stringify(localStorageFileUploaded)
    );
  };


  const filesAddedToDB = (index: number) => {
    let filesAddedToDBStatus: any = [...fileUploadStatus];
    filesAddedToDBStatus[index].isFileAddedToDB = true;
    setFileUploadStatus(filesAddedToDBStatus);
  };

  const displayFileUploadInProgress = () => {
    let localStorageFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
      : [];

    if (localStorageFileUploaded.length > 0) {
      const learnerFiles = localStorageFileUploaded.filter(
        (x: any) => x.section === "adminMaterial" && x.allocationId === allocationId
      );
      if (learnerFiles && learnerFiles.length > 0) {

        return learnerFiles[0].files.map((file: { fullFileName: string, fileName: string, fileSize: string }) => {
          // const existFileName = fileUploadStatus.filter(
          //   (x:any) => x.path === file.fileName
          // );
          var existFileName = false;
          fileUploadStatus.forEach((LiveFile: any, index) => {
            if (fileKey + removeSpecialCharacters(LiveFile.name) === file.fullFileName) {
              existFileName = true;
            }
          })
          // const existFileName = fileUploadStatus.findIndex((x:any) => `adminMaterial/${allocationId}/` + removeSpecialCharacters(x.path) === file.fullFileName);

          if (!existFileName) {
            return (
              <div key={file.fileName} className="filenameDisplay">
                <div className="singleFileUpload">
                  <div>
                    <div className="removeImageWrapper">
                      <Icon size="18" icon="single-incorrect" linkClass="helpLink"
                        onClkEvt={(e: any) => cancelFileUpload(file.fileName)}
                      />
                      {/*  */}
                    </div>
                    <div className="uploadFileNameWrapper">
                      {" "}
                      &nbsp;&nbsp;

                      {(file.fileName.length > 20) ? (
                        <div className="tooltip" >
                          <span className="uploadFileNameTxt">{file.fileName.replace(/[^\w\d_\-\.]+/ig, '').substring(0, 20)}...</span>
                          <span className="tooltiptext tooltiptextFile">
                            {file.fileName.replace(/[^\w\d_\-\.]+/ig, '')}
                          </span>
                        </div>) : (
                        <span className="uploadFileNameTxt">{file.fileName.replace(/[^\w\d_\-\.]+/ig, '')}</span>)}
                      <span className="uploadFileSizeTxt">
                        &nbsp;({sizeInBytes(parseInt(file.fileSize))})
                      </span>
                      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <pearson-progress-bar
                          type="loading"
                          progress={95}
                        ></pearson-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })

      }
      else { return <div></div> }
    }
    else { return <div></div> }
  }

  const getCredentials = async (fileName: string) => {
    const axiosConfig = {
      headers: {
        "content-type": "application/json"
      }
    };

    // const fileId =`${allocationId}/${removeSpecialCharacters(qualificationCode)}/${removeSpecialCharacters(unitCode)}/${removeSpecialCharacters(learnerCode)}/${removeSpecialCharacters(file.name)}`;
    const fileId = `adminMaterial/${allocationId}/` + removeSpecialCharacters(fileName);

    return await axios
      .post(
        apiEndPoint,
        JSON.stringify({
          fileName: fileName.replace(/[^\w\d_\-\.]+/gi, ""),
          allocationId: allocationId,
          fileId: fileId,

        }),
        axiosConfig
      )
      .then((postResponse: any) => {
        return postResponse;
      })
      .catch(error => {
        console.log("S3 Uploader Credentials error", error);
        return error;
      });
  };

  const cancelFileUpload = (fileName: string) => {
    Promise.resolve(getCredentials(fileName)).then(credentials => {
      if (credentials && credentials.data) {
        const uploader = new S3UploadMultipart(
          credentials && credentials.data,
          null,
          `adminMaterial/${allocationId}/` + removeSpecialCharacters(fileName)
        );
        uploader.abortUpload();
        removeSingleAdminFileLocalStorage(fileName);
      }
    })
  }

  useEffect(() => {
    setIsAdminFileUploaded(false);
    let localStorageFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
      : [];

    if (localStorageFileUploaded.length > 0) {
      prevUploadedAdminFiles.forEach((e: any) => {
        const leanerIndex = localStorageFileUploaded.findIndex(
          (x: any) => x.section === "adminMaterial" && x.allocationId === allocationId
        );

        if (leanerIndex !== -1) {
          const fullFileName = e.fileKey;
          const fileIndex = localStorageFileUploaded[leanerIndex].files.findIndex(
            (file: any) => file.fullFileName === fullFileName
          );
          if (fileIndex !== -1) {
            setIsAdminFileUploaded(true);
            if (localStorageFileUploaded[leanerIndex].files.length === 1) {
              localStorageFileUploaded.splice(leanerIndex, 1);
            }
            else {
              localStorageFileUploaded[leanerIndex].files.splice(fileIndex, 1);
            }
          }

        }
      })

      localStorage.setItem(
        "adminfileUploaded",
        JSON.stringify(localStorageFileUploaded)
      );
    }
  }, [prevUploadedAdminFiles && prevUploadedAdminFiles.length])



  useEffect(() => {
    fileUploadStatus.forEach((file: any, index) => {
      // eslint-disable-next-line
      { (!file.isFileUploaded) && setShowCancelAll(showCancelAll + 1) }
      //   if (file.isFileUploaded && !file.isFileAddedToDB) {
      //     addAdminMaterial(
      //       allocationId,
      //       `adminMaterial/${allocationId}/${file.name.replace(/[^\w\d_\-\.]+/ig, '')}`,
      //       `adminMaterial/${allocationId}/${file.name.replace(/[^\w\d_\-\.]+/ig, '')}`,
      //       file.name.replace(/[^\w\d_\-\.]+/ig, ''),
      //       file.size + "",
      //       FILE_USER_TYPE.ADMIN_LP
      //     );
      //     filesAddedToDB(index);
      //  // localStorage.setItem("isAddUpload", "true")
      //   }
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [fileUploadStatus]);
  const fileKey = `adminMaterial/${allocationId}/`;
  const mediaSetObj = {
    learningProviderId: "1",
    mediaSetId: "0409314d-f4c0-42b4-be44-d40419e405c2",
    learnerId: "2",
    unitId: "10",
    fileKey
  };
  const downloadFile = (fileKey: string, fileName: string) => {
    // console.log("download called")
    props.downloadAdminMaterial(allocationId, fileKey, 2, 1, 180, fileName)
  }
  const removeAllFiles = () => {
    //const getFilekeysArray = getFilekeys(prevUploadedAdminFiles)
    // console.log(getFilekeysArray)
    // if(getFilekeysArray.length > 0){
    let prevFileNames: any = [];
    prevUploadedAdminFiles && prevUploadedAdminFiles.map((f: any) => {
      //setPreviousFileNames([...previosFileNames,f.fileName])
      prevFileNames.push(f.fileName)
    })
    setRemoveAdminFileNames(prevFileNames);
    removeAdminmaterial(
      allocationId,
      "",
      true,
      FILE_USER_TYPE.ADMIN_LP
    );

    // }

  }

  const showFileRemovalButtons = () => {
    if (status === REQUSEST_STATUS.SUBMITTING) return false;
    if (status === REQUSEST_STATUS.SUBMITTED) return false;
    if (!unlocked && (status === REQUSEST_STATUS.IN_PROGRESS_C || status === REQUSEST_STATUS.RETURNED || status === REQUSEST_STATUS.NOT_SUBMITTED )) return false;
    return true;
  }

  const adminMaterialHeadingWithGuidance = () => (
    <>
      Administration Material
      <span className="shared-file-guide-msg">Not all subjects are required to use this section.  Subject specific guidance will advise what should be uploaded to this section.</span>
    </>
  )

  let showCancelAllIcon = false;
  if (showCancelAll >= 2) {
    showCancelAllIcon = true
  }

  const setRemoveFiles = (filename: any) => {
    // setRemoveFileName([...removeFileName,filename])
    const removeFileName: any = [filename]
    setRemoveAdminFileNames(removeFileName)
  }

  const checkNoFilesUpload = () => {
    if (
      (status === REQUSEST_STATUS.SUBMITTING ||
        status === REQUSEST_STATUS.SUBMITTED) && prevUploadedAdminFiles && prevUploadedAdminFiles.length === 0
    ) {
      return true
    } else {
      return false;
    }

  };

  return (
    <div className="uploadContainer">
      <S3UploaderAdminMaterial
        setFileUploadStatus={setFileUploadStatus}
        fileUploadStatus={fileUploadStatus}
        apiEndPoint={apiEndPoint}
        uploadTitle={adminMaterialHeadingWithGuidance}
        mediaSetObj={mediaSetObj}
        allocationId={allocationId}
        isCancelAll={showCancelAllIcon}
        onClickCancellAll={cancelAll}
        filesLength={prevUploadedAdminFiles && prevUploadedAdminFiles.length}
        removeAllFiles={removeAllFiles}
        status={status}
        previousAdminFiles={prevUploadedAdminFiles}
        removeAdminFileNames={removeAdminFileNames}
        adminMaterial={adminMaterial}
        addAdminMaterial={addAdminMaterial}
        unlocked={unlocked}
        lpAdminEvidenceStatus={lpAdminEvidenceStatus}
        hideUnlockStatusPill={hideUnlockStatusPill}
      />
      <div className="fileDisplayContainer">
        {checkNoFilesUpload() ? (<div style={{ padding: "10px" }}>Admin material not uploaded</div>) : (
          prevUploadedAdminFiles && prevUploadedAdminFiles.map((f: any) => {
            return (
              <div key={f.fileKey} className="filenameDisplay">
                <div className="singleFileUpload">
                  <div className="removeImageWrapper">
                    {showFileRemovalButtons() && HasWriteAccess(userLoginstate) ? (
                      <Icon size="18" icon="single-incorrect" onClkEvt={(e: any) => {
                        removeAdminmaterial(
                          allocationId,
                          f.fileKey,
                          false,
                          FILE_USER_TYPE.ADMIN_LP
                        ); setRemoveFiles(f.fileName)
                        // handlePostAddRemoveFiles(allocationId);
                      }} styleCss="gr-neutral-high-two" linkClass="helpLink" />
                      // </a>
                    ) : (<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
                  </div>
                  <div className="uploadFileNameWrapper">
                    {" "}
                    &nbsp;&nbsp;
                    {f.fileName.length > 20 ? (
                      <div className="tooltip" >
                        <span className="uploadFileNameTxt" style={{ cursor: (f.fileStatusCode == 2) ? "pointer" : "", color: (f.fileStatusCode == 2) ? "blue" : "" }} onClick={() => (f.fileStatusCode == 2) && downloadFile(f.fileKey, f.fileName)}>{f.fileName.substring(0, 20)}...</span>
                        <span className="tooltiptext tooltiptextFile">
                          {f.fileName}
                        </span>
                      </div>) : (
                      <span className="uploadFileNameTxt" style={{ cursor: (f.fileStatusCode == 2) ? "pointer" : "", color: (f.fileStatusCode == 2) ? "blue" : "" }} onClick={() => (f.fileStatusCode == 2) && downloadFile(f.fileKey, f.fileName)}>{f.fileName}</span>)}
                    <span className="uploadFileSizeTxt">
                      &nbsp;&nbsp;({sizeInBytes(parseInt(f.fileSize))})
                    </span>
                    <S3FileUploadStatus fileStatusCode={f.fileStatusCode} fileStatusTitle={f.fileStatusTitle} rejectedTxt={f.fileRejectedReason} fileName={f.fileName} fileCount={f.rejectedFilesCount} />
                  </div>
                </div>
              </div>
            );
          }))}

        {fileUploadStatus.map((file: any, index) => {
          return (
            <div>
              <div className="filenameDisplay">
                {file.showProgress && !file.isFileUploaded ? (
                  <div className="singleFileUpload">
                    {file.percentage >= 0 && file.percentage < 100 ? (
                      <div className="removeImageWrapper">
                        <Icon size="18" icon="single-incorrect" linkClass="helpLink"
                          onClkEvt={(e: any) => cancelSingleUpload(e, file.name, index)} />
                        {/* </a> */}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="uploadFileNameWrapper">
                      {" "}
                      &nbsp;&nbsp;
                      {file.name.length > 20 ? (
                        <div className="tooltip" >
                          <span className="uploadFileNameTxt">{file.name.replace(/[^\w\d_\-\.]+/ig, '').substring(0, 20)}...</span>
                          <span className="tooltiptext tooltiptextFile">
                            {file.name.replace(/[^\w\d_\-\.]+/ig, '')}
                          </span>
                        </div>) : (
                        <span className="uploadFileNameTxt">{file.name.replace(/[^\w\d_\-\.]+/ig, '')}</span>)}
                      <span className="uploadFileSizeTxt">
                        &nbsp;({sizeInBytes(parseInt(file.size))})
                      </span>
                      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <pearson-progress-bar
                          type="loading"
                          progress={(file.percentage.toString()) === "NaN" ? "100" : file.percentage}
                        ></pearson-progress-bar>
                      </div>
                    </div>

                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        {displayFileUploadInProgress()}
      </div>
    </div>)
};

const S3FileUploadStatus = (props: any) => (
  <>
    {(props.fileStatusCode === FILE_STATUS.UPLOADED) && (<div className="scanningTxt">{props.fileStatusTitle}</div>)}
    {(props.fileStatusCode === FILE_STATUS.ACCEPTED) && (<div className="acceptedTxt">{props.fileStatusTitle}</div>)}
    {(props.fileStatusCode === FILE_STATUS.REJECTED) && (<div><div className="rejectedTxt" style={{ float: "left" }} >
      {props.fileStatusTitle}</div> <span className="tooltip" style={{ paddingLeft: "10px" }}><Icon size="18" icon="warning" styleCss="gr-condition-one" />
        <span className="tooltiptext infotooltiptext tooltipRejectedStatusAM">
          {getRejectedReason(props.rejectedTxt + '', props.fileName, props.fileCount)}
        </span>
      </span></div>)}
  </>
)

const getRejectedReason = (responseRejectedReason: string, fileName: string, fileCount: number): string => {
  switch (responseRejectedReason.toUpperCase()) {
    case avatsFileStatus.AVATS_STATUS_INFECTED:
      return (fileName.includes('.zip') || fileName.includes('.rar')) ? avatsFileStatus.AVATS_STATUS_INFECTED_ZIP_TEXT.replace('[X]', fileCount ? fileCount.toString() : "0") : avatsFileStatus.AVATS_STATUS_INFECTED_TEXT
    case avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED:
      return (fileName.includes('.zip') || fileName.includes('.rar')) ? avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_ZIP_TEXT : avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_TEXT
    case avatsFileStatus.AVATS_STATUS_UNSUPPORTED:
      return avatsFileStatus.AVATS_STATUS_UNSUPPORTED_TEXT
    case avatsFileStatus.AVATS_STATUS_FILE_EXISTS:
      return avatsFileStatus.AVATS_STATUS_FILE_EXISTS_TEXT
    default:
      return avatsFileStatus.AVATS_STATUS_DEFAULT_TEXT
  }
}
export default FileUploadAdminMaterial;