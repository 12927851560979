import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import S3UploadMultipart from "./s3-upload-multipart";
import axios from "axios";
import ThreeDotsMenu from "../threeDotsMenu";
import { viewLearnerStatusOption } from "../../../models/option";
import Select from "../select";
import Icon from "../icon";
import Modal from "../CommonModal";
import moment from "moment";
import config from '../../../app-config'
import * as dbrdHelper from '../../../helpers/dashboard-helper'
import * as _ from "lodash";
import LearnerMaterial from "../../../models/learner-material";
import {HasWriteAccess } from "../../../components/common/user-validation";
import { useUserLoginState } from "../../../shared/context-api/hooks";
import { Badge } from "../badge";
import { REQUSEST_STATUS } from "../../../shared/app-enums";

function S3Uploader(props) {
  const {
    setFileUploadStatus,
    fileUploadStatus,
    apiEndPoint,
    learnerCodeName,
    mediaSetObj,
    allocationId,
    isCancelAll,
    onClickCancellAll,
    learnerDOB,
    learnerLateEntryDate,
    learnerIsLateEntry,
    isEvidenceAvailable,
    status,
    learnerStatusChange,
    learnerId,
    learnerStatusCode,
    learnerStatusTitle,
    qualificationCode,
    unitCode,
    learnerCode,
    removeAllFiles,
    learnerLateEntryOrgDate,
    evidenceSubmittedOn,
    evidenceReturnedOn,
    isSystemSelected,
    previousLearnerFiles,
    removeLearnerFileNames,
    learnerMaterial,
    fileKey,
    filterValue,
    addLearnerMaterial,
    isSubmitAllowed,
    unlocked,
    learnerEvidenceStatus,
    hideUnlockStatusPill
  } = props;
  const [filesToUpload, setToUpload] = useState([]);
  const [uploadLearnerFiles, setuploadLearnerFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [learnerStatus, setlearnerStatus] = useState(learnerStatusCode);
  const dropdownShowArr = [4, 7];
  const dropZoneShow = dropdownShowArr.includes(status) ? false : true;
  const [isRemoveConfirm, setSsRemoveConfirm] = useState(false);
  const [isStatusDropdownDisabled, setIsStatusDropdownDisabled] = useState(isEvidenceAvailable);
  const userLoginstate = useUserLoginState();

  const menuItems = () => {
    return (
      <ul>
        <li>
          {isCancelAll && HasWriteAccess(userLoginstate) ? (
            <a href="javascript:void(0)" onClick={onClickCancellAll}>
              Cancel All
            </a>
          ) : (
            <a href="javascript:void(0)" className="disabled" >
              Cancel All
            </a>
          )}
        </li>
        { isEvidenceAvailable  && isRemoveEnable() && HasWriteAccess(userLoginstate) && (
          <li>
            <a href="javascript:void(0)" onClick={openRemoveAllModal}>
              Remove All{" "}
            </a>
          </li>
        )}
      </ul>
    );
  };

  const isRemoveEnable = () => {
    if(previousLearnerFiles.length > 0){
      return true;
    } else{
      return false;
    }
  }

  const onOptionChange = event => {
    setlearnerStatus(event.target.value);
    learnerStatusChange(learnerId, event.target.value);
  };

  const openRemoveAllModal = () => {
    setSsRemoveConfirm(true);
  };

  const getCredentials = async (file) => {
    const axiosConfig = {
      headers: {
        "content-type": "application/json"
      }
    };
    
    // const fileId =`${allocationId}/${removeSpecialCharacters(qualificationCode)}/${removeSpecialCharacters(unitCode)}/${removeSpecialCharacters(learnerCode)}/${removeSpecialCharacters(file.name)}`;
     const fileId = fileKey+ removeSpecialCharacters(file.name);

    return await axios
      .post(
        apiEndPoint,
        JSON.stringify({
          fileName: file.name.replace(/[^\w\d_\-\.]+/gi, ""),
          allocationId: allocationId,
          fileId: fileId,
          learnerId: learnerId
        }),
        axiosConfig
      )
      .then(postResponse => {
        
        return  postResponse;
      })
      .catch(error => {
        console.log("S3 Uploader Credentials error", error);
        return error;
      });
  };

  useEffect(() => {
    if(learnerMaterial.responseCode === 409){
      setErrorMessage("File already exists. Please rename this file so it can be uploaded");
      setShowError(true);
    }
},[LearnerMaterial]);

  useEffect(() => {
    let prevFileNames = [];
    previousLearnerFiles &&
      previousLearnerFiles.map(f => {
        prevFileNames.push(f.fileName.toLowerCase());
      });
    setuploadLearnerFiles(prevFileNames);
  }, [previousLearnerFiles && previousLearnerFiles.length]);

  useEffect(() => { 
    if(uploadLearnerFiles.length === 0 && 
      previousLearnerFiles && previousLearnerFiles.length === 0){
      setIsStatusDropdownDisabled(false)
    }
  },[uploadLearnerFiles]);

  useEffect(() => {
    filesToUpload.forEach((f, index) => {
      let dragUploadFiles = [];
      if(f.size/ 1024 / 1024>config.MEDIA_FILES.MAX_FILE_SIZE){
        setErrorMessage("Upload not allowed (File size greater than 8GB)");
        setShowError(true);
      }  else if (f.size == 0) {
        removeSingleFileLocalStorage(f.name);
        setErrorMessage("This is 0 bytes and cannot be uploaded");
        setShowError(true);
      } else{
        if (uploadLearnerFiles.includes(f.name.toLowerCase())) {
          removeSingleFileLocalStorage(f.name);
          setErrorMessage("File already exists. Please rename this file so it can be uploaded");
          setShowError(true);
        } else {
          Promise.resolve(getCredentials(f)).then(credentials => {
            if (credentials.data && credentials.data.responseCode === 409) {
              removeSingleFileLocalStorage(f.name);
              setErrorMessage("File already exists. Please rename this file so it can be uploaded");
              setShowError(true);
              return;
            }
            if (credentials.data && credentials.data.responseCode === 403) {
              removeSingleFileLocalStorage(f.name);
              setErrorMessage("Upload not allowed (unsupported file format)");
              setShowError(true);
              return;
            }
            if (credentials.data && credentials.data.responseCode === 400 && credentials.data.responseText.includes("deleted")) {
              removeSingleFileLocalStorage(f.name);
              setErrorMessage("File removal is still in progress. Please rename this file so it can be uploaded.");
              setShowError(true);
              return;
            }
            else{
              setIsStatusDropdownDisabled(true);
            }
            dragUploadFiles.push(f.name.toLowerCase());
            let newPercentageStatus = [...fileUploadStatus];

            f["file"] = f;

            for (let key in mediaSetObj) {
              f[key] = mediaSetObj[key];
            }
            f["AllocationId"] = allocationId;
            const uploader = new S3UploadMultipart(
              credentials && credentials.data,
              f,
              fileKey+ removeSpecialCharacters(f.name)
            );
            //  `${allocationId}/${removeSpecialCharacters(qualificationCode)}/${removeSpecialCharacters(unitCode)}/${removeSpecialCharacters(learnerCode)}/${removeSpecialCharacters(f.name)}`

            uploader.addFileToUpload();
            uploader.startUpload();
            const cancelFile = () => {
              uploader.abortUpload();
            };
            uploader.onProgress = percentage => {
              if (newPercentageStatus[index].cancelUpload) {
                uploader.abortUpload();
              } else {
                let uploadPercentage = percentage * 100;
                uploadPercentage = Number(uploadPercentage.toFixed(0));
                newPercentageStatus[index].showProgress = true;
                newPercentageStatus[index].percentage = 100 -
                Math.round(
                  (percentage.remainingSize / percentage.fileSize) * 100
                );;
                newPercentageStatus[index].cancelFile = cancelFile;
                newPercentageStatus[index].isFileUploaded = false;
                newPercentageStatus[index].isFileAddedToDB = false;
                newPercentageStatus[index].isError = false;
                newPercentageStatus[index].cancelUpload = false;
                setFileUploadStatus([...newPercentageStatus]);
              }
            };

            uploader.onFinishS3Put = () => {
              newPercentageStatus[index].isFileUploaded = true;
              setFileUploadStatus([...newPercentageStatus]);
              addLearnerMaterial(
                allocationId,
                newPercentageStatus[index].fileKey+removeSpecialCharacters(newPercentageStatus[index].name),
                newPercentageStatus[index].fileKey+removeSpecialCharacters(newPercentageStatus[index].name),          
                newPercentageStatus[index].name.replace(/[^\w\d_\-\.]+/ig, ''),
                newPercentageStatus[index].size + "",
                newPercentageStatus[index].learnerId
                    );

              if(filterValue !== "All"){
                setuploadLearnerFiles([])
              }
            };

            uploader.onError = err => {
              console.log("S3 Uploader Error");
              newPercentageStatus[index].isError = true;
              newPercentageStatus[index].errorMsg = err;
              setFileUploadStatus([...newPercentageStatus]);
            };

            setuploadLearnerFiles([...uploadLearnerFiles, ...dragUploadFiles]);
          });
        }
      }
    });
    // eslint-disable-next-line
  }, [filesToUpload]);
  
  useEffect(() => {
    let upload = uploadLearnerFiles.filter(function(el) {
      return removeLearnerFileNames.indexOf(el) > 0;
    });
    setuploadLearnerFiles(upload);
  }, [removeLearnerFileNames]);

  useEffect(() => {
    setIsStatusDropdownDisabled(isEvidenceAvailable)
  },[isEvidenceAvailable]);

  const onDrop = useCallback(acceptedFiles => {
    const isError = setFileLocalStorage(acceptedFiles);
    if(isError) {
      return;
    }
    setShowError(false);
    setFileUploadStatus(acceptedFiles);
    setToUpload(acceptedFiles);
    // eslint-disable-next-line
  }, []);

  const confirmRemoveAllFile = () => {
    setSsRemoveConfirm(false);
    removeAllFiles();
  };

  const editingEvidenceIsEnabled = () => { 
    if (status === REQUSEST_STATUS.SUBMITTING || status === REQUSEST_STATUS.SUBMITTED) return false;
    if ((status === REQUSEST_STATUS.RETURNED || status === REQUSEST_STATUS.IN_PROGRESS_C || status === REQUSEST_STATUS.NOT_SUBMITTED) && 
        !(learnerIsLateEntry && moment(learnerLateEntryOrgDate).isAfter(evidenceSubmittedOn)) && 
        !unlocked
    ) {
      return false;
    }
    return true;
  };

  const statusSelectorIsEnabled = () => {
    if (!editingEvidenceIsEnabled()) return false;
    return !isStatusDropdownDisabled;
  }

  const removeSpecialCharacters = (value)=>{
    return value.replace(/[^\w\d_\-\.]+/gi,"")
  };
  
  const setFileLocalStorage = (acceptedFiles) => {
    var isError = false;
    var fileNames = [];
    let fullFileName = "";
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
    ? JSON.parse(localStorage.getItem("fileUploaded"))
    : [];
    
    acceptedFiles && acceptedFiles.forEach(file => {
      fullFileName = fileKey + removeSpecialCharacters(file.name);
      var inProgressFileExists = localStorageFileUploaded.some(ls => 
        ls.files.some(f => f.fullFileName === fullFileName));
      var alreadyFileExists = previousLearnerFiles && previousLearnerFiles.some(f => 
        f.fileKey === fullFileName);
      if (!inProgressFileExists && !alreadyFileExists) {
        fileNames.push({fullFileName: fullFileName, 
          fileName: removeSpecialCharacters(file.name), fileSize: file.size});
      }
      else {
        isError = true;
        setErrorMessage("File already exists. Please rename this file so it can be uploaded");
        setShowError(true);
        return isError;
      }
    });

    if(fileNames && fileNames.length > 0)
    {
      const index = localStorageFileUploaded.findIndex(ls => ls.learnerId === learnerId && ls.allocationId === allocationId);
      if (index !== -1) {
        localStorageFileUploaded[index].files.push(...fileNames);
      }
      else {
        localStorageFileUploaded.push({ learnerId: learnerId,allocationId:allocationId, files: fileNames });
      }

      localStorage.setItem("fileUploaded", JSON.stringify(localStorageFileUploaded));
    }
    return isError;
  }

  const removeSingleFileLocalStorage = (fileName) => {
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded"))
      : [];

    const leanerIndex = localStorageFileUploaded.findIndex(
      (ls) => ls.learnerId === learnerId && ls.allocationId === allocationId
    );
    if (leanerIndex !== -1) {
      const fullFileName = fileKey + removeSpecialCharacters(fileName);
      if (localStorageFileUploaded[leanerIndex].files.length === 1) {
        localStorageFileUploaded.splice(leanerIndex, 1);
      } else {
        const fileIndex = localStorageFileUploaded[leanerIndex].files.findIndex(
          (file) => file.fullFileName === fullFileName
        );
        if (fileIndex !== -1) {
          localStorageFileUploaded[leanerIndex].files.splice(fileIndex, 1);
        }
      }
    }

    localStorage.setItem("fileUploaded", JSON.stringify(localStorageFileUploaded));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <div className="uploadHeader" style={{ height: "auto"}}>  
        <div className="gr-grid-container learnerCardContainer ie-flex margin0 ">
          <div className="uploadtext gr-col-one  gr-col-span-five gr-h3 gr-font-normal semi-bold-650">
            {(isSystemSelected && (dbrdHelper.getCurrentAssessMethod() === 'moderation')) ? 
                <span className="isSystemselected" >
                
              P&nbsp;</span>:<></>
            }
            
            {learnerCodeName && _.trim(learnerCodeName).length  > 30 ? (
            <div className="tooltip" >
            {learnerCodeName && _.trim(learnerCodeName).substring(0,30)}...
            <span className="tooltiptext tooltipname " style={{width:"470px",top:"40px",lineHeight: "22px",left:"17%",fontWeight:"normal"}}>
            {learnerCodeName}
                      </span>
            </div>
            ):(<div>{learnerCodeName}</div>)}
          </div>

          {(status !== REQUSEST_STATUS.SUBMITTING && status !== REQUSEST_STATUS.SUBMITTED) ? (
            <div className="uploadtext gr-col-one  gr-col-span-five">
              <div className="dobLabel gr-neutral-med-one">Status</div>
              <div className="spacing ">&nbsp;</div>
              <div className="viewLearnerStatusDropdown">
                <span>
                  {" "}
                  <Select
                    disabled={!statusSelectorIsEnabled() || !HasWriteAccess(userLoginstate) ? true : null }
                    id="searchOption"
                    placeholder=""
                    value={learnerStatus}
                    options={viewLearnerStatusOption}
                    onChange={onOptionChange}
                  />
                </span>
              </div>

              <div
                className="tooltip"
                style={{ paddingLeft: "10px", paddingTop: "5px" }}
              >
                <Icon size="18" icon="information" />
                <span
                  className="tooltiptext tooltipLearnerStatus"
                  style={{ width: "500px" }}
                >
                  When a learner's status is changed to 'Absent' or 'No
                  Evidence', you are confirming that no evidence will be
                  uploaded and provided for assessment. You must still complete
                  any of the other required Pearson processes to confirm the
                  change of status.
                </span>
              </div>
            </div>
          ) : (
            <div className="uploadtext gr-col-one  gr-col-span-five">
              <div className="dobLabel gr-neutral-med-one">Status</div>
              <div className="spacing ">&nbsp;</div>
              <div className="statusText " >{learnerStatusTitle}</div>
            </div>
          )}

          <div className="uploadtext gr-col-one">
            {!(props.learnerEvidenceStatus === "None") && !hideUnlockStatusPill && 
              <Badge 
                pill 
                variant={props.learnerEvidenceStatus === "Unlocked" ? "primary" : "success"}
                label={props.learnerEvidenceStatus}
              />
            }
          </div>

          <div className=" gr-col-one learnersmenu"> 
            {editingEvidenceIsEnabled() && (
              <ThreeDotsMenu render={menuItems()} menuMaxWidth="120px" />
            )}
          </div>
      

          <div className="uploadtext uploadBottomRow gr-col-one  gr-col-span-five">
            <div className="dobLabel gr-neutral-med-one ">DOB</div>
            <div className="statusText ">{learnerDOB}</div>
          </div>

          <div className="uploadtext uploadBottomRow gr-col-one  gr-col-span-five">
            {learnerIsLateEntry ?
              <div>
                <div className="dobLabel gr-neutral-med-one ">Added</div>
                <div className="spacing" style={{ width: "0px" }}>&nbsp;</div>
                <div className="statusText" style={{ width: "71%" }}>
                  {learnerLateEntryDate}
                </div>
              </div> : null
            }
          </div>

        </div>
     </div>
      <div>
        {HasWriteAccess(userLoginstate) && editingEvidenceIsEnabled() && (parseInt(learnerStatus) === 1) && (
          <div className="uploaddragzoneLearnerCard">
            <section className="container">
              <div {...getRootProps({ className: "dropzone dragzone" })}>
                <input {...getInputProps()} />
                <p className="tooltipInit">
                  <span className="tooltipdropInit" id={isDragActive}>
                    Drop files here or <a style={{ cursor: "pointer" }}>Browse</a>
                  </span>
                </p>
              </div>
            </section>
          </div>
        )}
        <span style={{ float: "right", paddingRight: "3%", color: "red" }}>
          {showError ? (
            <>
              {errorMessage} &nbsp;&nbsp;
              <span
                style={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={e => setShowError(false)}
              >
                x
              </span>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </span>
        <Modal
          isConfirmBut={true}
          show={isRemoveConfirm}
          title="Remove All Files Confirmation"
          fromKebabMenu={true}
          modalData="removeAllFiles"
          onCancel={() => setSsRemoveConfirm(false)}
          onConfirm={() => confirmRemoveAllFile()}
          submitAllowed ={isSubmitAllowed}
        />
      </div>
    </>
  );
}

export default S3Uploader;
