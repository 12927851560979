import LearnerCard from "../../components/pearson/learnerCard"
import _ from 'lodash';
import { LearnerML } from "../../models/learner";
import { REQUSEST_STATUS } from "../../shared/app-enums";

export const viewLearners = (
  manageLearnerDetails:any, 
  learnersPerPageNumber: number, 
  currentPageNumber: number, 
  onCheckboxChange: ((e: any) => void),
  currentSelectedLearners: number[] = []
) => { 
    const vlReqStatusId = localStorage.getItem("VLReqStatusId");
    const requestStatus = vlReqStatusId ? parseInt(vlReqStatusId) : 0;
    const sortedRequests = sortLearners(manageLearnerDetails); 
    return sortedRequests.map((learnerData: any, index: any) => {
      if (!(learnerData.learnerEvidenceStatus === "Unlocked") && (requestStatus === REQUSEST_STATUS.IN_PROGRESS_C || requestStatus === REQUSEST_STATUS.RETURNED)) return <></>;
      const startFromIndex = learnersPerPageNumber*(currentPageNumber-1);
      const endIndex = (startFromIndex+learnersPerPageNumber)-1;
      if(startFromIndex <= index && endIndex >= index){
        return <div key={index+"s"}><LearnerCard 
          key={index} 
          isChecked={isCheckedManageLearner(learnerData, currentSelectedLearners)} 
          learner={learnerData} 
          onCheckboxChange={onCheckboxChange} />
        </div>
      }
    });
  }

  const sortLearners = (learnersData: LearnerML[]) => {
    const sortedRequests = _.orderBy(learnersData, [
      learner => learner && learner.learnerCode 
    ]);
    return sortedRequests;
  }

  export const isCheckedManageLearner = (learnerData: LearnerML, currentSelectedLearners: number[]) => {
    return (_.includes(currentSelectedLearners, learnerData && learnerData.learnerId))
  }

  export const searchLearnerGrouping = (learnerData: LearnerML[], searchOption: string, searchText: string) => {    
    return getGroupedRequests(learnerData, searchText);
  }

  export const getGroupedRequests = (learnerData: LearnerML[], searchText: string) => {
    const searchedLearners = searchOnProperties(learnerData, searchText);
    return sortLearners(searchedLearners);
  }

  const searchOnProperties = (learnerData: LearnerML[], searchTerm: string) => {
    const fields = ["learnerCode","learnerFirstName", "learnerLastName", "learnerDob"];
  
    return _.filter(learnerData, request => {
      
      return _(request).pick(fields).some((searchstring: any) => {
  
        let result = false;
        result = _(searchstring).toLower().includes(searchTerm) || _(searchstring).toUpper().includes(searchTerm) || _(searchstring).includes(searchTerm);
        
        if (!result) {
          let heading = (searchstring.toString().toLowerCase()).split(" ");
          let search = (searchTerm.toString().toLowerCase()).split(" ");
          let intern = search.filter(word => heading.filter((term: string) => term.includes(word)))
          result = Boolean(intern.find(word => { return _(searchstring).toLower().includes(word) }))
        }
        return result;
      });
    })
  }

  