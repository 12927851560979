import React, { useEffect } from "react";
// import axios from "axios";
import S3Uploader from "../pearson/uploader/s3uploader";
import config from "../../../src/app-config";
import "./upload.css";
import Icon from "./icon";
import sizeInBytes from "../../helpers/sizeInBytes";
import { fileStatusEnums as avatsFileStatus, FILE_STATUS, REQUSEST_STATUS } from '../../shared/app-enums';
import moment from "moment";
import axios from "axios";
import S3UploadMultipart from "./uploader/s3-upload-multipart";
// import { AVATS_STATUS_FILE_EXISTS_TEXT } from "./shared/constants";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { HasWriteAccess } from "../../components/common/user-validation";

const FileUploadViewerLearner = (props: any) => {
  const {
    allocationId,
    addLearnerMaterial,
    removeLearnerMaterial,
    prevUploadedFiles,
    //handlePostAddRemoveFiles,
    learnerCode,
    learnerName,
    learnerDOB,
    learnerLateEntryDate,
    learnerIsLateEntry,
    evidence,
    status,
    learningProviderCode,
    learnerStatusChange,
    learnerId,
    learnerStatusCode,
    learnerStatusTitle,
    qualificationCode,
    unitCode,
    learnerLateEntryOrgDate,
    evidenceSubmittedOn,
    evidenceReturnedOn,
    isSystemSelected,
    //removeAllFile
    onSharedIconClick,
    learnerMaterial,
    fileKey,
    filterValue,
    isSubmitAllowed,
    unlocked,
    learnerEvidenceStatus,
    hideUnlockStatusPill
  } = props;

  const [isFileUploaded, setIsFileUploaded] = React.useState<boolean>(false);
  const [fileUploadStatus, setFileUploadStatus] = React.useState([]);
  const [removeLearnerFileNames, setremoveLearnerFileNames] = React.useState([]);
  const [showCancelAll, setShowCancelAll] = React.useState(0)
  const [removeFileName, setRemoveFileName] = React.useState([]);
  const apiEndPoint = `${config.API_GATEWAY.URL}/exam-file-upload-token/get-admin`;
  const userLoginstate = useUserLoginState();


  const cancelSingleUpload = (e: any, fileName: any, index: any) => {
    let updateUploadStatus: any = [...fileUploadStatus];
    removeSingleFileLocalStorage(fileName);
    updateUploadStatus[index].cancelFile();
    updateUploadStatus[index].showProgress = false;
    updateUploadStatus[index].percentage = 0;
    updateUploadStatus[index].cancelUpload = true;
    setFileUploadStatus(updateUploadStatus);
    setRemoveFiles(fileName)

  };

  const cancelAll = () => {
    let cancelAllNames: any = [];
    removeFileLocalStorageForLearner();
    fileUploadStatus.forEach((file: any, index) => {
      if (file.percentage < 100 || isNaN(file.percentage)) {
        file.cancelFile();
        file.showProgress = false;
        file.percentage = 0;
        file.cancelUpload = true;//a//ddLearnerMaterials3
        cancelAllNames.push(file.name)
      }
    });
    const cancelAllStatus = [...fileUploadStatus];
    setFileUploadStatus(cancelAllStatus);
    setremoveLearnerFileNames(cancelAllNames)

    setRemoveFileName(cancelAllNames);
  };

  const removeSingleFileLocalStorage = (fileName: string) => {
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : [];

    const leanerIndex = localStorageFileUploaded.findIndex(
      (ls: any) => ls.learnerId === learnerId && ls.allocationId === allocationId
    );
    if (leanerIndex !== -1) {
      const fullFileName = fileKey + removeSpecialCharacters(fileName);
      if (localStorageFileUploaded[leanerIndex].files.length === 1) {
        localStorageFileUploaded.splice(leanerIndex, 1);
      } else {
        const fileIndex = localStorageFileUploaded[leanerIndex].files.findIndex(
          (file: any) => file.fullFileName === fullFileName
        );
        if (fileIndex !== -1) {
          localStorageFileUploaded[leanerIndex].files.splice(fileIndex, 1);
        }
      }
    }

    localStorage.setItem("fileUploaded", JSON.stringify(localStorageFileUploaded));
  };

  const removeFileLocalStorageForLearner = () => {
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : [];

    const index = localStorageFileUploaded.findIndex(
      (ls: any) => ls.learnerId === learnerId && ls.allocationId === allocationId
    );
    if (index !== -1) {
      localStorageFileUploaded.splice(index, 1);
    }

    localStorage.setItem("fileUploaded", JSON.stringify(localStorageFileUploaded));
  };

  const filesAddedToDB = (index: number) => {
    let filesAddedToDBStatus: any = [...fileUploadStatus];
    filesAddedToDBStatus[index].isFileAddedToDB = true;
    setFileUploadStatus(filesAddedToDBStatus);
  };

  const displayFileUploadInProgress = () => {
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : [];

    if (localStorageFileUploaded.length > 0) {
      const learnerFiles = localStorageFileUploaded.filter(
        (ls: any) => ls.learnerId === learnerId && ls.allocationId === allocationId
      );
      if (learnerFiles && learnerFiles.length > 0) {
        return learnerFiles[0].files.map((file: {fullFileName: string, fileName: string, fileSize: string}) => {
          // const existFileNameLearner = fileUploadStatus.findIndex((x:any) => fileKey + removeSpecialCharacters(x.path) === file.fullFileName);
          var existFileNameLearner = false;
          fileUploadStatus.forEach((LiveFile: any, index) => {
            if (fileKey + removeSpecialCharacters(LiveFile.name) === file.fullFileName) {
              existFileNameLearner = true;
            }
          })

          if (!existFileNameLearner) {
            return (
              <div key={file.fileName} className="filenameDisplay">
                <div className="singleFileUpload">
                  <div>
                    <div className="removeImageWrapper">
                      <Icon size="18" icon="single-incorrect" linkClass="helpLink"
                        onClkEvt={(e: any) => cancelFileUpload(file.fileName)} />
                    </div>
                    <div className="uploadFileNameWrapper">
                      {" "}
                      &nbsp;&nbsp;
                      {(file.fileName.length > 20) ? (
                        <div className="tooltip" >
                          <span className="uploadFileNameTxt">{file.fileName.replace(/[^\w\d_\-\.]+/ig, '').substring(0, 20)}...</span>
                          <span className="tooltiptext tooltiptextFile">
                            {file.fileName.replace(/[^\w\d_\-\.]+/ig, '')}
                          </span>
                        </div>) : (
                        <span className="uploadFileNameTxt">{file.fileName.replace(/[^\w\d_\-\.]+/ig, '')}</span>)}
                      <span className="uploadFileSizeTxt">
                        &nbsp;({sizeInBytes(parseInt(file.fileSize))})
                      </span>
                      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <pearson-progress-bar
                          type="loading"
                          progress={95}
                        ></pearson-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })
      }
      else { return <div></div> }
    }
    else { return <div></div> }
  }

  const getCredentials = async (fileName: string) => {
    const axiosConfig = {
      headers: {
        "content-type": "application/json"
      }
    };
    
    // const fileId =`${allocationId}/${removeSpecialCharacters(qualificationCode)}/${removeSpecialCharacters(unitCode)}/${removeSpecialCharacters(learnerCode)}/${removeSpecialCharacters(file.name)}`;
     const fileId = fileKey+ removeSpecialCharacters(fileName);

    return await axios
      .post(
        apiEndPoint,
        JSON.stringify({
          fileName: fileName.replace(/[^\w\d_\-\.]+/gi, ""),
          allocationId: allocationId,
          fileId: fileId,
          learnerId: learnerId
        }),
        axiosConfig
      )
      .then((postResponse: any) => {
        return postResponse;
      })
      .catch(error => {
        console.log("S3 Uploader Credentials error", error);
        return error;
      });
  };

  const cancelFileUpload = (fileName: string) => {
    Promise.resolve(getCredentials(fileName)).then(credentials => {
      if (credentials && credentials.data) {
        const uploader = new S3UploadMultipart(
          credentials && credentials.data,
          null,
          fileKey + removeSpecialCharacters(fileName)
        );
        uploader.abortUpload();
        removeSingleFileLocalStorage(fileName);
      }
    })
  }

  useEffect(() => {
    setIsFileUploaded(false);
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : [];

    if (localStorageFileUploaded.length > 0) {
      evidence.forEach((e: any) => {
        const leanerIndex = localStorageFileUploaded.findIndex(
          (ls: any) => ls.learnerId === learnerId && ls.allocationId === allocationId
        );

        if (leanerIndex !== -1) {
          const fullFileName = e.fileKey;
          const fileIndex = localStorageFileUploaded[leanerIndex].files.findIndex(
            (file: any) => file.fullFileName === fullFileName
          );
          if (fileIndex !== -1) {
            setIsFileUploaded(true);
            if (localStorageFileUploaded[leanerIndex].files.length === 1) {
              localStorageFileUploaded.splice(leanerIndex, 1);
            }
            else {
              localStorageFileUploaded[leanerIndex].files.splice(fileIndex, 1);
            }
          }

        }
      })

      localStorage.setItem("fileUploaded", JSON.stringify(localStorageFileUploaded));
    }
  }, [evidence && evidence.length])

  useEffect(() => {
    fileUploadStatus.forEach((file: any, index) => {
      { (!file.isFileUploaded) && setShowCancelAll(showCancelAll + 1) }

    });

  }, [fileUploadStatus]);


  const mediaSetObj = {
    learningProviderId: learningProviderCode,
    learnerId: learnerId,
    unitId: "",
    fileKey
  };

  const removeSpecialCharacters = (value: any) => {
    return value.replace(/[^\w\d_\-\.]+/ig, "")
  };

  const removeAllFiles = () => {
    let prevFileNames: any = [];
    const getFilekeysArray = getFilekeys(evidence)
    // console.log(getFilekeysArray)
    evidence && evidence.map((f:any) => {
        //setPreviousFileNames([...previosFileNames,f.fileName])
        prevFileNames.push(f.fileName)
      })
      setremoveLearnerFileNames(prevFileNames);
    if(getFilekeysArray.length > 0){
      removeLearnerMaterial(
        allocationId,
        "",
        learnerId,
        true
      );

    }

  }



  const getFilekeys = (evidenceGetFileKeys: any) => {
    let fileKeyArr: string[] = [];
    if (evidenceGetFileKeys && evidenceGetFileKeys.length > 0) {
      evidenceGetFileKeys.map((f: any) => {
        fileKeyArr.push(f.fileKey);
      })
    }
    return fileKeyArr;
  }

  const downloadFile = (fileKey: string, fileName: string) => {
    // console.log("download called")
    props.downloadAdminMaterial(allocationId, fileKey, 1, 1, 180, fileName)
  }


  const setRemoveFiles = (filename:any) =>{
    // setRemoveFileName([...removeFileName,filename])
    const removeFileName:any = [filename]
    setremoveLearnerFileNames(removeFileName)
  }



  const showFileRemovalButtons = () => { 
    if (status === REQUSEST_STATUS.SUBMITTING || status === REQUSEST_STATUS.SUBMITTED) return false;
    if ((status === REQUSEST_STATUS.RETURNED || status === REQUSEST_STATUS.IN_PROGRESS_C || status === REQUSEST_STATUS.NOT_SUBMITTED) && 
        !(learnerIsLateEntry && moment(learnerLateEntryOrgDate).isAfter(evidenceSubmittedOn)) && 
        !unlocked
    ) {
      return false;
    }
    return true;
  };

  const handleSharedIconClick = (e:any, fileKey:string, fileName:string) => {
    localStorage.setItem("VLIconClicksharedFilename",fileName)
    props.onSharedIconClick && props.onSharedIconClick(e, fileKey)
  }
  
  return (
    <div className="uploadContainer">
      <S3Uploader
        setFileUploadStatus={setFileUploadStatus}
        fileUploadStatus={fileUploadStatus}
        apiEndPoint={apiEndPoint}
        learnerCodeName={learnerCode + " " + learnerName}
        mediaSetObj={mediaSetObj}
        allocationId={allocationId}
        isCancelAll={showCancelAll >= 2 ? true : false}
        onClickCancellAll={cancelAll}
        learnerDOB={learnerDOB}
        learnerLateEntryDate={learnerLateEntryDate}
        learnerIsLateEntry={learnerIsLateEntry}
        isEvidenceAvailable={evidence.length > 0 ? true : false}
        status={status}
        learnerStatusChange={learnerStatusChange}
        learnerId={learnerId}
        learnerStatusCode={learnerStatusCode}
        learnerCode={learnerCode}
        qualificationCode={qualificationCode}
        unitCode={unitCode}
        learnerStatusTitle={learnerStatusTitle}
        // console.log("isSubmitAllowed after", isSubmitAllowed)
        removeAllFiles={removeAllFiles}
        learnerLateEntryOrgDate={learnerLateEntryOrgDate}
        evidenceSubmittedOn={evidenceSubmittedOn}
        evidenceReturnedOn={evidenceReturnedOn}
        isSystemSelected={isSystemSelected}
        previousLearnerFiles={evidence}
        removeLearnerFileNames={removeLearnerFileNames}
        learnerMaterial={learnerMaterial}
        fileKey={fileKey}
        filterValue={filterValue}
        addLearnerMaterial={addLearnerMaterial}
        isSubmitAllowed={isSubmitAllowed}
        unlocked={unlocked}
        learnerEvidenceStatus={learnerEvidenceStatus}
        hideUnlockStatusPill={hideUnlockStatusPill}
      />
      <div className="fileDisplayContainer">
        {(evidence && evidence.length > 0) && evidence.map((f: any) => {
          return (
            <div key={f.fileKey} className="filenameDisplay">
              <div className="singleFileUpload">
                <div className="removeImageWrapper">
                  {/* {((status !== 4 && status !== 7) && (f.fileStatusCode+"" !== '1' || f.fileStatusCode+"" === '2' || f.fileStatusCode+"" === '3') && checkRemoveDisplay(f.fileStatusCode+""))?( */}
                  {showFileRemovalButtons() && !f.isSharedFile && HasWriteAccess(userLoginstate) ? (

                  // <a
                  //   href="javascript:void(0)"
                  //   onClick={(e) => {
                  //     removeAdminmaterial(
                  //       allocationId,
                  //       [f.fileKey],
                  //       learnerId,
                  //       false
                  //     );
                  //     // handlePostAddRemoveFiles(allocationId);
                  //   }}
                  // >
                    <Icon size="18" icon="single-incorrect" styleCss="gr-neutral-high-two" onClkEvt={(e:any) => {removeLearnerMaterial(
                        allocationId,
                        f.fileKey,
                        learnerId,
                        false
                      );setRemoveFiles(f.fileName)
                      // handlePostAddRemoveFiles(allocationId);
                    }} linkClass="helpLink"
                  ></Icon>
                  // </a>
                  ): (f.isSharedFile)? (
                    <Icon 
                      size="18" icon="group" 
                      linkClass="helpLink"                       
                      styleCss="gr-neutral-high-two" 
                      onClkEvt={(e:any)=>{                          
                        handleSharedIconClick(e, f.fileKey, f.fileName);
                      }}
                    />
                  ) : ""}
                </div>
                <div className="uploadFileNameWrapper">
                  {" "}
                  &nbsp;&nbsp;
                  {(f.fileName.length > 20) ? (
                      <div className="tooltip" >
                        <span className="uploadFileNameTxt" style={{ cursor: (f.fileStatusCode == 2) ? "pointer" : "", color: (f.fileStatusCode == 2) ? "blue" : "" }} onClick={() => (f.fileStatusCode == 2) && downloadFile(f.fileKey, f.fileName)}>{f.fileName.substring(0, 20)}...</span>          
                        <span className="tooltiptext tooltiptextFile">
                          {f.fileName}
                        </span>
                      </div>):(
                        <span className="uploadFileNameTxt" style={{ cursor: (f.fileStatusCode == 2) ? "pointer" : "", color: (f.fileStatusCode == 2) ? "blue" : "" }} onClick={() => (f.fileStatusCode == 2) && downloadFile(f.fileKey, f.fileName)}>{f.fileName}</span>)}
                  <span className="uploadFileSizeTxt">
                    &nbsp;&nbsp;({sizeInBytes(parseInt(f.fileSize))})
                  </span>
                  <S3FileUploadStatus fileStatusCode={f.fileStatusCode} fileStatusTitle={f.fileStatusTitle} rejectedTxt={f.fileRejectedReason} fileName={f.fileName} fileCount={f.rejectedFilesCount} />
                </div>
              </div>
            </div>
          );
        })}
        {(fileUploadStatus && fileUploadStatus.length > 0) && fileUploadStatus.map((file: any, index) => {
          return (
            <div>
              <div className="filenameDisplay">
                {file.showProgress && !file.isFileUploaded ? (
                  <div className="singleFileUpload">
                    {file.percentage >= 0 && file.percentage < 100 ? (
                      <div>
                        {file.fileStatusCode + "" !== '1' ? (
                          <div className="removeImageWrapper">
                            {/* <a
                          href="javascript:void(0)"
                          onClick={(e) => cancelSingleUpload(e, file.name, index)}
                        > */}
                            <Icon size="18" icon="single-incorrect" linkClass="helpLink"
                              onClkEvt={(e: any) => cancelSingleUpload(e, file.name, index)} />
                            {/* </a> */}
                          </div>) : 
                          (<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>)
                          
                          } </div>
                    ) : (
                      ""
                    )}
                    <div className="uploadFileNameWrapper">
                      {" "}
                      &nbsp;&nbsp;
                      {(file.name.length > 20) ? (
                        <div className="tooltip" >
                          <span className="uploadFileNameTxt">{file.name.replace(/[^\w\d_\-\.]+/ig, '').substring(0, 20)}...</span>
                          <span className="tooltiptext tooltiptextFile">
                            {file.name.replace(/[^\w\d_\-\.]+/ig, '')}
                          </span>
                        </div>) : (
                        <span className="uploadFileNameTxt">{file.name.replace(/[^\w\d_\-\.]+/ig, '')}</span>)}
                      <span className="uploadFileSizeTxt">
                        &nbsp;({sizeInBytes(parseInt(file.size))})
                      </span>
                      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <pearson-progress-bar
                          type="loading"
                          progress={(file.percentage.toString()) === "NaN" ? "100" : file.percentage}
                        ></pearson-progress-bar>
                      </div>
                    </div>

                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        {displayFileUploadInProgress()}
      </div>
    </div>
  )
};

const S3FileUploadStatus = (props: any) => (
  <>
    {(props.fileStatusCode === FILE_STATUS.UPLOADED) && (<div className="scanningTxt">{props.fileStatusTitle}</div>)}
    {(props.fileStatusCode === FILE_STATUS.ACCEPTED) && (<div className="acceptedTxt">{props.fileStatusTitle}</div>)}
    {(props.fileStatusCode === FILE_STATUS.REJECTED) && (<div><div className="rejectedTxt" style={{ float: "left" }} >
      {props.fileStatusTitle}</div> <span className="tooltip" style={{ paddingLeft: "10px" }}><Icon size="18" icon="warning" styleCss="gr-condition-one" />
        <span className="tooltiptext tooltipRejectedStatus">
          {getRejectedReason(props.rejectedTxt + '', props.fileName, props.fileCount)}
        </span>
      </span></div>)}
  </>
)

const getRejectedReason = (responseRejectedReason: string, fileName: string, fileCount: number): string => {
  switch (responseRejectedReason.toUpperCase()) {
    case avatsFileStatus.AVATS_STATUS_INFECTED:
      return (fileName.includes('.zip') || fileName.includes('.rar')) ? avatsFileStatus.AVATS_STATUS_INFECTED_ZIP_TEXT.replace('[X]', fileCount.toString()) : avatsFileStatus.AVATS_STATUS_INFECTED_TEXT
    case avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED:
      return (fileName.includes('.zip') || fileName.includes('.rar')) ? avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_ZIP_TEXT : avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_TEXT
    case avatsFileStatus.AVATS_STATUS_UNSUPPORTED:
      return avatsFileStatus.AVATS_STATUS_UNSUPPORTED_TEXT
    case avatsFileStatus.AVATS_STATUS_FILE_EXISTS:
      return avatsFileStatus.AVATS_STATUS_FILE_EXISTS_TEXT
    default:
      return avatsFileStatus.AVATS_STATUS_DEFAULT_TEXT
  }
}

export default FileUploadViewerLearner;
