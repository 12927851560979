import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { handleAuthentication, getTokens, logout } from "./actions/auth/auth";
import RouteEventPublisher from './components/common/route-event-publisher';

const uuid = require("uuid/v4");

export const requireBearerToken = () => {
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (config.url?.indexOf("oauth2") === -1) {
      return handleAuthentication()
        .then(() => {
          config.headers.set('AccessToken', getTokens().access_token);
          config.headers.set('Authorization', getTokens().id_token);
          // config.headers['x-correlation-id'] = uuid();
          return Promise.resolve(config);
        })
        .catch(() => {
          return Promise.resolve(config);
        });
    } else {
      return config;
    }
  });
};

export const requireCorrelationId = () => {
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers.set('x-correlation-id', uuid());
    return config;
  });
};

export const requireSessionId = () => {
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if(localStorage.getItem("x-session-id") === null){
      localStorage.setItem("x-session-id", uuid())
    }
    config.headers.set('x-session-id', localStorage.getItem("x-session-id"));
    return config;
  });
};

export const responseInterceptors = () => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // toastr.error(ErrorMessages.SESSION_TIMEOUT, undefined, {
        //     onHidden: () => { logout() }
        // });
        logout();
      }

      if (error && error.response && error.response.status === 503) {
        //let publisher = RouteEventPublisher.getInstance();
        //publisher.publish('Maintenance', '');
        alert("Something went wrong, please refresh or retry.");
       }

       if (error && error.message === 'Network Error') {
        //let publisher = RouteEventPublisher.getInstance();
        //publisher.publish('Maintenance', '');
        alert("Network error, please check and retry.");
       }

      return Promise.reject(error);
    }
  );
};