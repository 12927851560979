import * as React from "react";
import * as _ from "lodash";
import Icon from "./icon";
import Input from "./input";
// import { ViewLearnersSummary } from "../../models/learner";
// import { getSavedSearch, updateSavedSearch } from "../../helpers/dashboard-request-helper";
// import SavedSearch from '../../models/saved-search';

interface ViewLearnerSearchBarProps {
  startDisabled: boolean;
  placeholderText?: string;
  searchText: string;
  onSearch: (e: any, searchText: string) => void;
  onTextChange: (e: any) => void;
  // onSearchUpdate: (T:string, O:string) => void;
  allLearnersFilter:(e:any) => void;
  acceptedFilter:(e:any) => void;
  scanningFilter:(e:any)=> void;
  rejectedFilter:(e:any)=> void;
  nofilesFilter:(e:any)=> void;
  evidenceUnlockedFilter:(e:any)=> void;
  filterValue:string;
  learnersCount : any
}

interface LocalState {
  // searchOption: string;
  // searchText: string;
  inputDisabled: boolean;
}

export class ViewLearnerSearchBar extends React.Component<
  ViewLearnerSearchBarProps,
  LocalState
> {
  static defaultProps = {
    startDisabled: false,
    placeholderText: "",
    selectedOption: "",
    selectedText: "",
    filterValue :"All",
    
  };

  
  raiseDoSearchWhenUserStoppedTyping = _.debounce(() => {
    // this.props.onSearch(this.props.searchOption, this.state.searchText);
  }, 300);

  constructor(props: ViewLearnerSearchBarProps) {
    super(props);
    this.state = {
      // searchOption: this.props.searchOption,
      // searchText: this.props.searchText,
      inputDisabled: this.props.startDisabled,
    };
    
  }

  // handleCriteriaChange = (e: React.FormEvent<HTMLInputElement>) => {
  //   this.setState({ searchText: e.currentTarget.value }, () => {
  //     this.raiseDoSearchWhenUserStoppedTyping();
  //   });
  // };

  
  _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      this.props.onSearch(e, this.props.searchText);
    }
  };

  resetSearchText = (e: any) => {
    this.props.onTextChange("");
    this.props.onSearch(e, "");
  };

  render() {
    return (
      <div className=" gr-grid-container margin0 topPad10">
        <div className="gr-form-element input gr-col-one gr-col-span-three ">
          <Input
            id={"searchText" + Date.now()}
            placeholder={this.props.placeholderText}
            value={this.props.searchText}
            disabled={this.props.startDisabled}
            onChange={this.props.onTextChange}
            onKeyDown={this._handleKeyDown}
          />
          <button
            className="search-bar-btn"
            style={{ position: "relative" }}
            onClick={this.resetSearchText}
          >
            <Icon size="18" icon="close" styleCss="gr-condition-one" />
          </button>
        </div>
        <div className="gr-form-element button gr-col-one gr-col-span-one ">
          <button
            style={{ marginTop: "10px" }}
            onClick={(e: any) => this.props.onSearch(e, this.props.searchText)}
          >
            <Icon icon="search" size="18" />
          </button>
        </div>
        
        <div className="gr-form-element input gr-col-one gr-col-span-eight">
          <div className={this.props.filterValue === 'All'? "allLearnersFilter active":"allLearnersFilter"} id="allLearners" onClick={this.props.allLearnersFilter}>
            All Learners ({this.props.learnersCount.allLearners})
          </div>
          <div className={this.props.filterValue === 'Scanning'? "allLearnersFilter active":"allLearnersFilter"} id="scanningFilter" onClick={this.props.scanningFilter}>
            Uploaded ({this.props.learnersCount.scanning})
          </div>
          <div className={this.props.filterValue === 'Accepted'? "allLearnersFilter active":"allLearnersFilter"} id="acceptedFilter" onClick={this.props.acceptedFilter}>
            Accepted ({this.props.learnersCount.accepted})
          </div>
          <div className={this.props.filterValue === 'Rejected'? "allLearnersFilter active":"allLearnersFilter"} id="rejectedFilter"  onClick={this.props.rejectedFilter}>
            Rejected ({this.props.learnersCount.rejected})
          </div>
          <div className={this.props.filterValue === 'NoFiles'? "allLearnersFilter active":"allLearnersFilter"} id="nofilesFilter"  onClick={this.props.nofilesFilter}>
            Present - No Files ({this.props.learnersCount.presentWithNoFiles})
          </div>
          <div className={this.props.filterValue === 'Unlocked'? "allLearnersFilter active":"allLearnersFilter"} id="unlocked"  onClick={this.props.evidenceUnlockedFilter}>
            Unlocked ({this.props.learnersCount.unlocked})
          </div>
        </div>
      </div>
    );
  }
}

export default ViewLearnerSearchBar;
